(function() {
    var t, e, n;
    window._Shopify = window._Shopify || {}, e = function(t, e) {
        return ("undefined" != typeof console && null !== console ? console.log : void 0) && (("undefined" != typeof ShopifyApp && null !== ShopifyApp ? ShopifyApp.debug : void 0) || ("undefined" != typeof ShopifyPOS && null !== ShopifyPOS ? ShopifyPOS.debug : void 0) || e) && !window.Teaspoon ? console.log("ShopifyApp " + t) : void 0
    }, n = function(t) {
        return ("undefined" != typeof console && null !== console ? console.warn : void 0) && !window.Teaspoon ? console.warn("ShopifyApp " + t) : e(t, !0)
    }, _Shopify.messageSlug = function(t) {
        var e, n;
        for (e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", t = (t || "message") + "_", n = 0; 16 > n; n++) t += e.charAt(Math.floor(Math.random() * e.length));
        return t
    }, null == _Shopify.Messenger && (_Shopify.Messenger = {
        modalMessages: [],
        messageHandlers: {},
        callbackPrefix: "onetime_callback",
        reset: function() {
            return this.messageHandlers = {}, this.modalMessages = []
        },
        handlersFor: function(t) {
            return this.messageHandlers[t] || []
        },
        addHandler: function(t, e, n) {
            return this.messageHandlers[t] || (this.messageHandlers[t] = []), n && this.modalMessages.push(t), this.messageHandlers[t].push(e)
        },
        setHandler: function(t, e) {
            return this.messageHandlers[t] = [e]
        },
        invokeHandlers: function(t, e) {
            var n, r, i, o, a, s;
            for (s = t.substring(0, this.callbackPrefix.length) === this.callbackPrefix, o = this.handlersFor(t), a = [], r = 0, i = o.length; i > r; r++) n = o[r], n(t, e), s ? a.push(this.removeHandler(t, n)) : a.push(void 0);
            return a
        },
        addOnetimeHandler: function(t) {
            var e;
            if (null != t) return e = _Shopify.messageSlug(this.callbackPrefix), this.addHandler(e, t), e
        },
        removeHandler: function(t, e) {
            var n;
            return n = this.messageHandlers[t].indexOf(e), this.messageHandlers[t].splice(n, 1), 0 === this.messageHandlers[t].length ? delete this.messageHandlers[t] : void 0
        },
        clearModal: function() {
            var t, e, n, r;
            for (r = this.modalMessages, t = 0, e = r.length; e > t; t++) n = r[t], delete this.messageHandlers[n];
            return this.modalMessages = []
        }
    }), _Shopify.AppBase || (_Shopify.AppBase = t = function() {
        function t() {}
        return t.reset = function() {
            return _Shopify.Messenger.reset(), this.debug = !1, this.forceRedirect = !0, this.apiKey = "", this.shopOrigin = "", this._isReady = !1
        }, t.getWindowLocation = function() {
            return window.location
        }, t.setWindowLocation = function(t) {
            return window.location = t
        }, t.bindWindowLocation = function() {
            return _Shopify.Messenger.addHandler("Shopify.API.setWindowLocation", function(t) {
                return function(e, n) {
                    return t.setWindowLocation(n)
                }
            }(this))
        }, t._isReady = !1, t.initWithData = function(t) {
            return this._isReady = !0
        }, t.ready = function(t) {
            return this._isReady ? void setTimeout(t, 0) : _Shopify.Messenger.addHandler("Shopify.API.initialize", function(e) {
                return function(n, r) {
                    return e.initWithData(r), t()
                }
            }(this))
        }, t.bindEvents = function() {
            return _Shopify.Messenger.addHandler("Shopify.API.print", function(t) {
                return function(e, n) {
                    return window.focus(), t.print()
                }
            }(this)), window.addEventListener("message", this.__addEventMessageCallback.bind(this), !1), window.addEventListener("scroll", _Shopify.Utils.throttle(function(t) {
                return function() {
                    return t.messageScrollPosition()
                }
            }(this), 200), !1), this.bindWindowLocation()
        }, t.messageScrollPosition = function() {
            return this.postMessage("Shopify.API.reportScroll", {
                position: window.pageYOffset
            })
        }, t.init = function(t) {
            return null == t && (t = {}), this._hasCalledInit ? void 0 : (this._hasCalledInit = !0, this.reset(), this.loadConfig(t), this.checkFrame(), this.bindEvents(), this.postMessage("Shopify.API.initialize", {
                debug: this.debug,
                apiKey: this.apiKey
            }))
        }, t.checkFrame = function() {
            var t;
            return window === window.parent ? (t = (this.shopOrigin || "https://myshopify.com") + "/admin/apps/", this.apiKey && (t = t + this.apiKey + this.getWindowLocation().pathname + (this.getWindowLocation().search || "")), this.forceRedirect ? (e("detected that it was not loaded in an iframe and is redirecting to: " + t, !0), this.setWindowLocation(t)) : n("detected that it was not loaded in an iframe but redirecting is disabled! Redirect URL would be: " + t)) : void 0
        }, t.loadConfig = function(t) {
            return this.apiKey = t.apiKey, this.shopOrigin = t.shopOrigin, this.forceRedirect = t.hasOwnProperty("forceRedirect") ? !!t.forceRedirect : this.forceRedirect = !0, this.debug = !!t.debug, this.apiKey || n("warning: apiKey has not been set."), this.shopOrigin || n("warning: shopOrigin has not been set."), this.shopOrigin && !this.shopOrigin.match(/^http(s)?:\/\//) ? n("warning: shopOrigin should include the protocol") : void 0
        }, t.print = function() {
            return window.print()
        }, t.window = function() {
            return window.parent.frames["app-iframe"]
        }, t.postMessage = function(t, n) {
            var r;
            return null == n && (n = {}), r = JSON.stringify({
                message: t,
                data: n
            }), e("client sent " + r + " to " + this.shopOrigin), window.parent.postMessage(r, this.shopOrigin), null != n ? n.callbackId : void 0
        }, t.flashNotice = function(t) {
            return this.postMessage("Shopify.API.flash.notice", {
                message: t
            })
        }, t.flashError = function(t) {
            return this.postMessage("Shopify.API.flash.error", {
                message: t
            })
        }, t.handlers = function() {
            return _Shopify.Messenger.messageHandlers
        }, t.__addEventMessageCallback = function(t) {
            var r, i, o, a, s;
            if (t.origin !== this.shopOrigin) return e("client received " + t.data + " from unknown origin " + t.origin + ". Expected " + this.shopOrigin + ".");
            e("client received " + t.data + " from " + t.origin);
            try {
                a = JSON.parse(t.data)
            } catch (o) {
                return i = o, void n("client received invalid JSON and cannot process the message. " + i + " : " + t.data + " : " + JSON.stringify(t.data))
            }
            null != a.data && this.initWithData(a.data), "Shopify.API.Modal.close" === a.message && (_Shopify.Messenger.clearModal(), "function" == typeof(r = ShopifyApp.Modal).__callback && r.__callback(a.data.result, a.data.data)), _Shopify.Messenger.invokeHandlers(a.message, a.data), (s = document.querySelector('form[data-shopify-app-submit="' + a.message + '"]')) && s.submit()
        }, t
    }()), null == _Shopify.Utils && (_Shopify.Utils = {
        throttle: function(t, e, n) {
            var r, i;
            return null == e && (e = 250), i = r = void 0,
                function() {
                    var o, a, s;
                    return a = n || this, s = +new Date, o = arguments, i && i + e > s ? (clearTimeout(r), r = setTimeout(function() {
                        return i = s, t.apply(a, o)
                    }, e)) : (i = s, t.apply(a, o))
                }
        }
    })
}).call(this),
    function() {
        var t, e, n, r = function(t, e) {
                return function() {
                    return t.apply(e, arguments)
                }
            },
            i = function(t, e) {
                function n() {
                    this.constructor = t
                }
                for (var r in e) o.call(e, r) && (t[r] = e[r]);
                return n.prototype = e.prototype, t.prototype = new n, t.__super__ = e.prototype, t
            },
            o = {}.hasOwnProperty;
        t = {
            build: function(t, e, n) {
                return null == n && (n = {}), this.addCallbacks(this.addMessages(t, e, n), n)
            },
            addMessages: function(t, e, n) {
                var r, i, o, a;
                if (null == e && (e = "button"), null == n && (n = {}), Array.isArray(t))
                    for (i = 0, o = t.length; o > i; i++) r = t[i], this.addMessages(r, e, n);
                else null != t && (t.message || (t.message = _Shopify.messageSlug("button_" + e)), t.href && !t.target && (t.target = "app"), "dropdown" === t.type && (t.links = function() {
                    var e, n, r, i;
                    for (r = t.links, i = [], e = 0, n = r.length; n > e; e++) a = r[e], i.push(this.addMessages(a, "link"));
                    return i
                }.call(this)));
                return t
            },
            addCallbacks: function(t, e) {
                var r, i, o, a;
                if (null == e && (e = {}), Array.isArray(t))
                    for (i = 0, o = t.length; o > i; i++) r = t[i], this.addCallbacks(r, e);
                return null != t && ("app" === t.target && (t.callback = function(e) {
                    return function(e, r) {
                        return n.setWindowLocation(t.href)
                    }
                }(this)), null != t.callback && _Shopify.Messenger.setHandler(t.message, t.callback, e.modal), e.dropdown && "dropdown" === t.type && (t.links = function() {
                    var e, n, r, i;
                    for (r = t.links, i = [], e = 0, n = r.length; n > e; e++) a = r[e], i.push(this.addCallbacks(a, {
                        modal: !1
                    }));
                    return i
                }.call(this))), t
            }
        }, e = function() {
            function t(t) {
                this.CURRENCIES = t, this.getCurrency = r(this.getCurrency, this)
            }
            return t.prototype.getCurrency = function(t) {
                var e, n, r, i;
                for (i = this.CURRENCIES, n = 0, r = i.length; r > n; n++)
                    if (e = i[n], e.code === t) return e
            }, t
        }(), window.ShopifyApp || (window.ShopifyApp = n = function(n) {
            function r() {
                return r.__super__.constructor.apply(this, arguments)
            }
            return i(r, n), r.bindEvents = function() {
                return _Shopify.Messenger.addHandler("Shopify.API.initialize", function(t) {
                    return function(e, n) {
                        return t.replaceState(t.getWindowLocation().pathname + t.getWindowLocation().search)
                    }
                }(this)), r.__super__.constructor.bindEvents.apply(this, arguments)
            }, r.initWithData = function(t) {
                return null != t.User && (this.User = t.User), null != t.CurrencyDB && (this.CurrencyDB = new e(t.CurrencyDB.CURRENCIES)), r.__super__.constructor.initWithData.apply(this, arguments)
            }, r.pushState = function(t) {
                return r.postMessage("Shopify.API.pushState", {
                    location: t
                })
            }, r.replaceState = function(t) {
                return r.postMessage("Shopify.API.replaceState", {
                    location: t
                })
            }, r.setDate = function(t, e) {
                return null == e && (e = null), r.postMessage("Shopify.API.DateRangeControl", {
                    start: t,
                    end: e
                })
            }, r.redirect = function(t) {
                return r.postMessage("Shopify.API.redirect", {
                    location: t
                })
            }, r.fetchAnalyticsToken = function(t) {
                var e;
                return e = "Shopify.API.fetchAnalyticsToken", _Shopify.Messenger.addHandler(e, t), r.postMessage(e)
            }, r.Nav = {
                initialize: function(t) {
                    return t || (t = []), r.postMessage("Shopify.API.Nav.initialize", t)
                },
                select: function(t) {
                    return t ? r.postMessage("Shopify.API.Nav.select", t) : void 0
                }
            }, r.Sidebar = {
                setSection: function(t) {
                    return t ? r.postMessage("Shopify.API.Sidebar.setSection", t) : void 0
                }
            }, r.Bar = {
                initialize: function(e) {
                    var n, i, o, a, s, u, l, c;
                    for (e || (e = {}), null != (null != (a = e.buttons) ? a.dateRangeControl : void 0) && _Shopify.Messenger.setHandler("Shopify.API.DateRangeControl", function(t) {
                            return function(t, n) {
                                return e.buttons.dateRangeControl.callback(n)
                            }
                        }(this)), s = ["primary", "secondary", "tertiary"], i = 0, o = s.length; o > i; i++) n = s[i], t.build(null != (u = e.buttons) ? u[n] : void 0, n, {
                        dropdown: !0
                    });
                    return t.build(e.breadcrumb, "breadcrumb"), (null != (l = e.pagination) ? l.next : void 0) && t.build(e.pagination.next, "pagination_next"), (null != (c = e.pagination) ? c.previous : void 0) && t.build(e.pagination.previous, "pagination_previous"), r.postMessage("Shopify.API.Bar.initialize", e), document.body.onclick = function() {
                        return r.postMessage("Shopify.API.Bar.closeDropdown")
                    }
                },
                loadingOn: function() {
                    return r.postMessage("Shopify.API.Bar.loading.on")
                },
                loadingOff: function() {
                    return r.postMessage("Shopify.API.Bar.loading.off")
                },
                setIcon: function(t) {
                    return r.postMessage("Shopify.API.Bar.icon", {
                        icon: t
                    })
                },
                setTitle: function(t) {
                    return r.postMessage("Shopify.API.Bar.title", {
                        title: t
                    })
                },
                setBreadcrumb: function(e) {
                    var n;
                    return n = {
                        breadcrumb: t.build(e, "breadcrumb")
                    }, r.postMessage("Shopify.API.Bar.breadcrumb", n)
                },
                disableAllButtons: function() {
                    return r.postMessage("Shopify.API.Bar.disableAllButtons")
                },
                setPagination: function(e) {
                    var n, i, o;
                    return n = {
                        pagination: e
                    }, (null != (i = n.pagination) ? i.next : void 0) && t.build(n.pagination.next, "pagination_next"), (null != (o = n.pagination) ? o.previous : void 0) && t.build(n.pagination.previous, "pagination_previous"), r.postMessage("Shopify.API.Bar.pagination", n)
                }
            }, r.Modal = {
                __callback: void 0,
                window: function() {
                    return window.parent.frames["app-modal-iframe"]
                },
                open: function(e, n) {
                    var i, o, a, s, u;
                    for (s = ["primary", "secondary", "tertiary"], o = 0, a = s.length; a > o; o++) i = s[o], t.build(null != (u = e.buttons) ? u[i] : void 0, i, {
                        dropdown: !0,
                        modal: !0
                    });
                    return r.Modal.__callback = n, r.postMessage("Shopify.API.Modal.open", e)
                },
                alert: function(t, e) {
                    return r.Modal.__callback = e, r.postMessage("Shopify.API.Modal.alert", {
                        message: t
                    })
                },
                confirm: function(t, e) {
                    return r.Modal.__callback = e, r.postMessage("Shopify.API.Modal.confirm", {
                        message: t
                    })
                },
                input: function(t, e) {
                    return r.Modal.__callback = e, r.postMessage("Shopify.API.Modal.input", {
                        message: t
                    })
                },
                close: function(t, e) {
                    return r.postMessage("Shopify.API.Modal.close", {
                        result: t,
                        data: e
                    })
                },
                productPicker: function(t, e) {
                    return r.Modal.__callback = e, r.postMessage("Shopify.API.Modal.productPicker", t)
                },
                collectionPicker: function(t, e) {
                    return r.Modal.__callback = e, r.postMessage("Shopify.API.Modal.collectionPicker", t)
                },
                setHeight: function(t) {
                    return r.postMessage("Shopify.API.Modal.setHeight", {
                        height: t
                    })
                }
            }, r.Reporting = {
                refreshToken: function(t) {
                    var e;
                    return e = "Shopify.API.Reporting.refreshToken", _Shopify.Messenger.addHandler(e, t), r.postMessage(e)
                }
            }, r
        }(_Shopify.AppBase))
    }.call(this),
    function() {
        var t, e, n, r, i, o, a;
        e = {}, t = "https://analytics.shopify.com", ShopifyApp.Analytics = {
            fetch: function(t) {
                if (!t.query) throw new Error("Shopify Analytics: You must provide a shopifyQl `query` to fetch.");
                if (!t.success) throw new Error("Shopify Analytics: You must provide a `success` callback to fetch.");
                if (!t.error) throw new Error("Shopify Analytics: You must provide an `error` callback to fetch.");
                return Boolean(e.token) ? i(t) : o(t)
            },
            clear: function() {
                return e = {}
            }
        }, o = function(t) {
            return ShopifyApp.fetchAnalyticsToken(function(n, r) {
                return r.error ? t.error(r.error) : (e = {
                    token: r.token,
                    userId: r.userId,
                    source: "analytics-sdk"
                }, i(t))
            })
        }, i = function(t) {
            var i;
            if (Boolean(XMLHttpRequest) && Boolean(JSON) && Boolean(encodeURIComponent)) return i = new XMLHttpRequest, i.open("GET", n(t.query), !0), i.onreadystatechange = function() {
                var n;
                if (4 === i.readyState) return 200 === i.status && Boolean(i.responseText) ? (n = r(i.responseText), t.success(n)) : i.responseText.match(/token signature invalid/) ? (e.token = null, ShopifyApp.Analytics.fetch(shopifyQl, t)) : t.error(i.responseText)
            }, i.send()
        }, r = function(t) {
            var e, n, r;
            return e = JSON.parse(t), {
                result: {
                    columns: (null != e && null != (n = e.result) ? n.columns : void 0) || [],
                    data: (null != e && null != (r = e.result) ? r.data : void 0) || []
                }
            }
        }, n = function(n) {
            return t + "/query?" + a({
                q: n,
                token: e.token,
                user_id: e.userId || "",
                source: e.source || "analytics-api"
            })
        }, a = function(t) {
            var e;
            return e = function(e) {
                return [e, t[e]].map(encodeURIComponent).join("=")
            }, Object.keys(t).map(e).join("&")
        }
    }.call(this),
    function() {}.call(this);